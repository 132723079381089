import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
// import { CognitoUserAmplify } from "@aws-amplify/ui-react/node_modules/@aws-amplify/ui";
import awsExports from "./aws-exports";
import ThemeProvider from "./redux/providers/theme-provider";
import PersistProvider from "./redux/providers/persist-provider";
import { store } from "./redux/store";
import App from "./App";

const port: string = location.hostname === "localhost" ? ":3000" : "";
const redirect = `${location.protocol}//${location.hostname}${port}/`;

const updatedAwsConfig = {
    ...awsExports,
    oauth: {
        ...awsExports.oauth,
        domain: "auth.saycle.com",
        redirectSignIn: `${redirect}`,
        redirectSignOut: `${redirect}`,
    },
};

Amplify.configure(updatedAwsConfig);

ReactDOM.render(
    <Authenticator>
        {() => (
            <Provider store={store}>
                <PersistProvider>
                    <ThemeProvider>
                        <App />
                    </ThemeProvider>
                </PersistProvider>
            </Provider>
        )}
    </Authenticator>,
    document.getElementById("root")
);
