import { useAppSelector } from "../redux/hooks";

// Language definitions start here
const terms: any = {
    English: {
        EN: "English",
        BG: "Български",
    },
    Welcome: {
        EN: "Welcome",
        BG: "Добре дошли",
    },
    Page: {
        EN: "Page",
        BG: "Страница",
    },
    Dashboard: {
        EN: "Dashboard",
        BG: "Табло",
    },
    "Sales Monitoring": {
        EN: "Sales Monitoring",
        BG: "Мониторинг продажби",
    },
    "Website Analytics": {
        EN: "Уебсайт анализ",
        BG: "Табло",
    },
    Cryptocurrency: {
        EN: "Cryptocurrency",
        BG: "Криптовалута",
    },
    "Helpdesk Management": {
        EN: "Helpdesk Management",
        BG: "Поддръжка",
    },
    Apps: {
        EN: "Apps",
        BG: "Приложения",
    },
    Calendar: {
        EN: "Calendar",
        BG: "Календар",
    },
    Chat: {
        EN: "Chat",
        BG: "Чат",
    },
    Contacts: {
        EN: "Contacts",
        BG: "Контакти",
    },
    "File Manager": {
        EN: "File Manager",
        BG: "Файлов мениджър",
    },
    Mail: {
        EN: "Mail",
        BG: "Поща",
    },
    Pages: {
        EN: "Pages",
        BG: "Страници",
    },
    Authentication: {
        EN: "Authentication",
        BG: "Удостоверяване",
    },
    "Sign In": {
        EN: "Sign In",
        BG: "Вход",
    },
    "Sign Up": {
        EN: "Sign Up",
        BG: "Регистрация",
    },
    "Verify Account": {
        EN: "Verify Account",
        BG: "Потвърждение на акаунт",
    },
    "Forgot Password": {
        EN: "Forgot Password",
        BG: "Забравена парола",
    },
    "Error Pages": {
        EN: "Error Pages",
        BG: "Страници за грешки",
    },
    "404 Page Not Found": {
        EN: "404 Page Not Found",
        BG: "404 Не е намерена",
    },
    "500 Internal Server": {
        EN: "500 Internal Server",
        BG: "500 Грешка в сървъра",
    },
    "503 Service Unavailable": {
        EN: "503 Service Unavailable",
        BG: "503 Недостъпен",
    },
    "505 Forbidden": {
        EN: "505 Forbidden",
        BG: "505 Забранен достъп",
    },
    "User Pages": {
        EN: "User Pages",
        BG: "Потребителски страници",
    },
    "View Profile": {
        EN: "View Profile",
        BG: "Преглед на профила",
    },
    Connections: {
        EN: "Connections",
        BG: "Връзки",
    },
    Groups: {
        EN: "Groups",
        BG: "Групи",
    },
    Events: {
        EN: "Events",
        BG: "Събития",
    },
    "Other Pages": {
        EN: "Other Pages",
        BG: "Други страници",
    },
    Timeline: {
        EN: "Timeline",
        BG: "Времева линия",
    },
    Pricing: {
        EN: "Pricing",
        BG: "Цени",
    },
    "Help Center": {
        EN: "Help Center",
        BG: "Помощна страница",
    },
    Invoice: {
        EN: "Invoice",
        BG: "Фактура",
    },
    Components: {
        EN: "Components",
        BG: "Компоненти",
    },
    Collections: {
        EN: "Collections",
        BG: "Колекции",
    },
    "View all Messages": {
        EN: "View all Messages",
        BG: "Виж всички съобщения",
    },
    "Recent Searches": {
        EN: "Recent Searches",
        BG: "Последни търсения",
    },
    "Search Suggestions": {
        EN: "Search Suggestions",
        BG: "Предложения за търсене",
    },
    NOTIFICATIONS: {
        EN: "NOTIFICATIONS",
        BG: "ИЗВЕСТИЯ",
    },
    "View all Notifications": {
        EN: "View all Notifications",
        BG: "Виж всички известия",
    },
    "Type a keyword E.g. Lawyers in London": {
        EN: "Type a keyword, E.g. Lawyers in London",
        BG: "Въведете фраза, пр. Адвокати в София",
    },
    Search: {
        EN: "Search",
        BG: "Търсене",
    },
    "Need some guidance? Please visit our": {
        EN: " Need some guidance? Please visit our",
        BG: " Нуждаете се от насоки? Моля посетете",
    },
    "search guide": {
        EN: " search guide",
        BG: " ръководството за търсене",
    },
};

export const T = (keyword: string): any => {
    const language = useAppSelector((state) => state.language);
    if (Object.keys(terms).includes(keyword)) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        return terms[keyword][language.code];
    }
    return keyword;
};
