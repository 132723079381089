/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Language } from "@saycle/shared/types";
// import { langTerms } from "../../locales/translation";

export interface LanguageState {
    code: Language;
}

const initialState: LanguageState = {
    code: "EN" as Language,
};

const languageSlice = createSlice({
    name: "language-terms",
    initialState,
    reducers: {
        toggleLanguage(state, action: PayloadAction<{ code?: Language }>) {
            // let data;
            // state.language === "BG" ? (data = dataBG) : (data = dataEN);
            switch (action.payload.code) {
                case "EN":
                    state.code = "EN";
                    break;
                case "BG":
                    state.code = "BG";
                    break;
                default:
                    state.code = "EN";
                    break;
            }
        },
        // toggleLanguage(state, action: PayloadAction<{ language?: Language }>) {
        //     let data;
        //     switch (action.payload.language) {
        //         case "EN":
        //             data = dataEN;
        //             break;
        //         case "BG":
        //             data = dataBG;
        //             break;
        //         default:
        //             data = dataEN;
        //             break;
        //     }
        // },
    },
});

export const { toggleLanguage } = languageSlice.actions;

export default languageSlice.reducer;
